
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
export default defineComponent({
    props: {
        max: {
            type: [Number, undefined] as PropType<number | undefined>,
            default: undefined,
        },
        amount: {
            type: Number,
            default: 0,
        },
        isBlocked: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'amount',
            required: false,
        },
    },
    emits: ['changeAmount'],
    setup(props, { emit }) {
        const currentAmount = ref(0)
        if (props.max) {
            watch(currentAmount, (newValue: number | string) => {
                // cannot set value bigger than max
                if (props.max && newValue > props.max) {
                    currentAmount.value = props.max
                }
            })
        }
        // watch for expected amount
        watch(
            () => props.amount,
            (newValue: number) => {
                currentAmount.value = newValue
            },
        )

        const resetAmountWhenEmpty = (amount: number | string) => {
            if (amount === '' || amount < 0) {
                currentAmount.value = 0
            }
            emit('changeAmount', currentAmount.value)
        }

        const increment = () => {
            if (props.isBlocked) {
                return false
            }
            if (props.max) {
                if (currentAmount.value < props.max) {
                    currentAmount.value++
                    emit('changeAmount', currentAmount.value)
                }
            } else {
                currentAmount.value++
                emit('changeAmount', currentAmount.value)
            }
        }

        const decrement = () => {
            if (props.isBlocked) {
                return false
            }
            if (currentAmount.value > 0) {
                currentAmount.value--
                emit('changeAmount', currentAmount.value)
            }
        }

        onMounted(() => {
            currentAmount.value = props.amount
        })
        return {
            currentAmount,
            increment,
            decrement,
            resetAmountWhenEmpty,
        }
    },
})
