import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "max", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-inputAmount", { 'm-inputAmount--disabled': _ctx.isBlocked }])
  }, [
    _createElementVNode("button", {
      class: "m-inputAmount__button m-inputAmount__button--decrement",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args)))
    }, [
      _createVNode(_component_a_icon, { icon: "Minus" })
    ]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentAmount) = $event)),
      class: "m-inputAmount__amount",
      type: "number",
      name: _ctx.name,
      max: _ctx.max,
      min: "0",
      disabled: _ctx.isBlocked,
      placeholder: "0",
      step: "1",
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resetAmountWhenEmpty(_ctx.currentAmount)))
    }, null, 40, _hoisted_1), [
      [_vModelText, _ctx.currentAmount]
    ]),
    _createElementVNode("button", {
      class: "m-inputAmount__button m-inputAmount__button--increment",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args)))
    }, [
      _createVNode(_component_a_icon, { icon: "Plus" })
    ])
  ], 2))
}