
import { defineComponent, ref } from 'vue'
import { useCarWash } from '@/composables/useCarWash'
import InputAmount from '@/components/InputAmount.vue'
import { useRouter } from 'vue-router'
import { IBarrel, IBarrelLevel } from '@/interfaces/IBarrel'

export default defineComponent({
    components: {
        InputAmount,
    },
    props: {
        barrelId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { activeCarWash, activeCarWashStock, getBarrelDetails, updateBarrelLevel } =
            useCarWash()
        const router = useRouter()
        const barrelLevelToAdd = ref<IBarrelLevel | null>()
        const isProductChosen = ref(false)
        const barrel = ref<IBarrel | undefined>(getBarrelDetails(+props.barrelId))
        const resetProductToAdd = () => {
            barrelLevelToAdd.value = null
            isProductChosen.value = false
        }

        const getBarrelAmount = (barrelId: number) => {
            if (barrelLevelToAdd.value && barrelLevelToAdd.value.id === barrelId) {
                return barrelLevelToAdd.value.capacityUsageInPercents
            }
        }
        const setBarrelLevel = (barrel: IBarrel | undefined, capacityUsageInPercents: number) => {
            if (capacityUsageInPercents === 0) {
                isProductChosen.value = false
                return
            }
            isProductChosen.value = true
            if (barrel) {
                const objectToPush: IBarrelLevel = {
                    id: barrel.id,
                    expectedCapacityUsageInPercents: barrel.expectedCapacityUsageInPercents,
                    capacityUsageInPercents,
                }
                barrelLevelToAdd.value = objectToPush
            }
        }
        const submitBarrelLevel = async () => {
            if (barrel.value && barrelLevelToAdd.value) {
                await updateBarrelLevel(
                    barrel.value?.id,
                    barrelLevelToAdd.value?.capacityUsageInPercents,
                )
                router.push({ name: 'CarWashDetails', params: { id: activeCarWash.value?.id } })
            }
        }
        return {
            barrel,
            activeCarWash,
            activeCarWashStock,
            resetProductToAdd,
            barrelLevelToAdd,
            getBarrelAmount,
            setBarrelLevel,
            submitBarrelLevel,
            isProductChosen,
            router,
        }
    },
})
